<template>
  <div style="background-color: #f5f5f5; min-height: 100vh">
    <transition name="flixFadeIn">
      <div :key="JSON.stringify(data)">
        <div v-if="!data">
          <signIn :callback="setData" />
        </div>
        <div v-else>
          <div class="flix-container flix-form-group">
            <div class="flix-row">
              <div class="flix-col-md-12">
                <div class="flix-well flix-text-center">
                  <h1>
                    {{ $tc('message.checkIn', 1) }} /
                    {{ $tc('message.checkOut', 1) }}
                  </h1>
                  <checkInCheckOut
                    :data="data"
                    :callback="
                      () => {
                        updateKey += 1
                      }
                    "
                  />
                </div>
              </div>
              <div class="flix-col-md-3">
                <notes :data="data" :key="updateKey" />
              </div>
              <div class="flix-col-md-9">
                <showData :data="data" />
              </div>
            </div>
            <div class="flix-row">
              <div class="flix-col-md-12 flix-text-right">
                <div
                  class="flix-col-md-2 flix-col-md-flix-offset-10 flix-col-xs-6 flix-col-xs-flix-offset-3 flix-text-right"
                >
                  <logo class="logo" />
                </div>
                <div class="flix-col-md-12">
                  <a
                    href="#"
                    class="flix-html-a flix-text-danger"
                    @click.prevent="data = false"
                    ><flixIcon id="remove" /> {{ $t('message.logout') }}</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  components: {
    notes() {
      return import('./notes')
    },
    signIn() {
      return import('./signIn')
    },
    showData() {
      return import('./showData')
    },
    checkInCheckOut() {
      return import('./edit/index.vue')
    }
  },
  props: {},
  data() {
    return {
      data: false,
      updateKey: 0
    }
  },
  computed: {},
  mounted() {},
  methods: {
    setData(ret) {
      this.data = ret
    }
  }
}
</script>
<style lang="sass" scoped></style>
